import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../layouts'
import './index.css'
import Frun from '../images/kettle-creek.jpg'
import CPine from '../images/pine-creek.jpg'
import Monument from '../images/fyling-horse.jpg'
import CommonHero from '../components/commonhero/commonhero.js'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout head={{ title: 'Monument' }}>
        <CommonHero
          pageTitle="Monument"
          custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1562736075/brianboals/downtown-monument.jpg"
        />
        <main className="bg-white p-relative">
          <div className="container py-5">
            <p className="text-center">
              Small town atmosphere with shops and restaurants and great 4 th of
              July parade! Mix of old and new housing.
            </p>
          </div>
          <section className="py-5">
            <div className="container">
              <div className="intro text-center mb-5">
                <h2>Neighborhoods</h2>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${Monument})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-redrockranch.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Red Rock Ranch"
                        >
                          Red Rock Ranch
                        </a>
                      </h4>
                      <p>
                        Located west of downtown Monument this neighborhood
                        features large spacious lots, and mountain and city
                        views!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${CPine})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-palmerlake.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Palmer Lake"
                        >
                          Palmer Lake
                        </a>
                      </h4>
                      <p>
                        Small town living with easy access to Monument and
                        Colorado Springs. This charming town is situated around
                        a beautiful pond and offers tons of hiking trails!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${Frun})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-gleneagle.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Gleneagle"
                        >
                          Gleneagle
                        </a>
                      </h4>
                      <p>
                        Located north of Colorado Springs and south of Monument,
                        this community has plenty to offer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default IndexPage
